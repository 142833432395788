import { useMutation, useQueryClient } from "vue-query";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
import { reactive } from "@vue/reactivity";

const action = async (payload) => {
  const { priceListId } = payload;
  const response = await httpReq.put(`ordering-portal/account-information-maintenance/price-lists/${priceListId}`, {
    name: payload.name,
    type: payload.type,
    margin: payload.margin,
    start_date: payload.startDate,
    end_date: payload.endDate,
    is_for_all_customers: payload.isForAllCustomers || false,
    available_for_new_customers: payload.availableForNewCustomers || false,
    is_default: payload.isDefault,
    product_margin_list: payload.productMarginList
  });
  return response.data
};

export default function useUpdatePriceList() {
  const cache = useQueryClient();
  const mutation = useMutation((payload) => action(payload), {
    onError: (error) => {
      store.commit("errorsModal/openModal", error.response.data.errors);
    },
    onSuccess: () => {
      cache.invalidateQueries(["price-lists"]);
      cache.invalidateQueries(["price-list-product-margins"])
      // toast.success("Account update.");
    },
    onSettled: () => {},
  });

  return reactive({
    updatePriceList: mutation.mutateAsync,
    updatePriceListStatus: mutation,
  });
}
