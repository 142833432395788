<template>
  <InlinePreloader
    v-if="isEdit && (isLoadingPricelist || isCustomersLoading || attachCustomersStatus.isLoading)"
    message="Loading..."></InlinePreloader>
  <div v-else
       :class="{'tw-opacity-60 tw-animate-pulse tw-duration-300 tw-pointer-events-none': createPriceListStatus.isLoading || updatePriceListStatus.isLoading}">

      <div v-if="isDefaultPriceList">
          <label class="form__label">
              <span class="form__labelTitle tw-font-bold">Installation Fee </span>
              <template v-if="!isEditInstallationFee">
                  <span >{{ $filters.formatMoney(installationFeeValue)}}</span>&nbsp;
                  <span @click="isEditInstallationFee = true">
                    <svg-icon name="solid/pencil"/>
                  </span>
              </template>
              <template v-else>
                  $<Field
                          :rules="{ required: true ,decimal:2}"
                          as="input"
                          class="form__input installation_fee"
                          label="Installation Fee"
                          name="installationFee"
                          type="number"
                          value=""
                          v-model="installationFeeValue"
                  />
                  &nbsp;
                  <span  @click="updateInstallationFee">
                    <svg-icon name="solid/check"/>
                  </span>
                  &nbsp;
                  <span  @click="isEditInstallationFee = false">
                    <svg-icon name="solid/times"/>
                  </span>
                  <!--              <span class="form__error">{{ errors.installationFee }}</span>-->
              </template>
          </label>
      </div>
    <Form v-slot="{ errors }" :initialValues="{
        name: isEdit? currentData.name: '',
        priceListType: isEdit? currentData.type: 'variable',
        margin: isEdit? currentData.latest_values.margin: '',
        }" @submit="onSubmit">

      <div
        class=" tw-bg-secondary tw-shadow-md tw-p-3 tw-flex tw-items-center tw-justify-between tw-gap-10">
        <button class="btn btn_default tw-m-0 tw-p-0 tw-w-max tw-text-white tw-border-none"
                type="button"
                @click="$router.back">
          <svg-icon name="solid/chevron-left"></svg-icon>
        </button>

        <div>

<!--          <button   v-if="isEdit && currentData.type === 'variable' && !isDefaultPriceList"-->
<!--                    :class="{'tw-bg-primary tw-text-white' : markThisPriceListAsTheDefaultOne,-->
<!--                  ' hover:tw-bg-primary hover:tw-text-white' : currentData.type !== 'fixed'}"-->
<!--                    class="tw-w-max tw-mr-2 tw-whitespace-nowrap tw-btn-secondary-success tw-bg-transparent"-->
<!--                    @click.prevent="markThisPriceListAsTheDefaultOne = !markThisPriceListAsTheDefaultOne"-->
<!--          >-->
<!--            MAKE DEFAULT-->
<!--          </button>-->
          <button class="tw-btn-primary-success tw-w-max" type="submit">
            {{ isEdit ? 'SAVE' : 'CREATE' }}
          </button>

        </div>
      </div>

      <!-- Form -->
      <div class="tw-max-w-xs tw-mt-4">
        <label class="form__label tw-mt-0" v-if="!isDefaultPriceList">
          <span class="form__labelTitle">Name*</span>
          <Field
            :rules="{ required: true }"
            as="input"
            class="form__input"
            label="Name"
            name="name"
            placeholder="Name"
            type="text"
            value=""
          />
          <span class="form__error">{{ errors.name }}</span>
        </label>
        <div v-if="!isDefaultPriceList" :class="{'tw-pointer-events-none tw-opacity-80' : isDefaultPriceList}">
          <label class="">
            <span class="form__labelTitle">Price list type</span>
          </label>
          <div class="tw-flex tw-gap-4">
            <label class="form__label form__label_row">
              <Field
                :disabled="isDefaultPriceList"
                :rules="{ required: true }"
                class="form__radio"
                hidden
                label="Type"
                name="priceListType"
                type="radio"
                value="variable"
                v-model="type"
              />
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle">Variable</span>
            </label>

            <label class="form__label form__label_row">
              <Field
                :disabled="isDefaultPriceList"
                :rules="{ required: true }"
                class="form__radio"
                hidden
                label="Type"
                name="priceListType"
                type="radio"
                value="fixed"
                v-model="type"
              />
              <span class="form__radio_icon"></span>
              <span class="form__labelTitle">Fixed</span>
            </label>
          </div>
          <div class="form__error">{{ errors.priceListType }}</div>
        </div>
        <label class="form__label">
          <span class="form__labelTitle">Default Margin(%)*</span>
          <Field
            :rules="{ required: true ,decimal:2}"
            as="input"
            class="form__input"
            label="Margin"
            name="margin"
            type="text"
            value=""
            v-model="defaultMargin"
          />
          <span class="form__error">{{ errors.margin }}</span>
        </label>

        <label class="form__label tw-m-0" v-if="!isDefaultPriceList">

          <div class="tw-flex tw-gap-4">
            <div>
              <span class="form__labelTitle">Start Date</span>
              <Datepicker
                id="datepicker_header"
                v-model="startDate"
                @update:model-value="endDate = null"
                :enable-time-picker="false"
                :format="formatDate"
                :preview-format="formatDate"
                name="dateRangecc"
                :min-date="new Date()"
                placeholder="Select a date"
                required
              />
            </div>

            <div>
              <span class="form__labelTitle">End Date</span>
              <Datepicker
                id="datepicker_header2"
                v-model="endDate"
                :enable-time-picker="false"
                :format="formatDate"
                :preview-format="formatDate"
                name="dateRange2"
                :min-date="moment(startDate).add(1,'d')"
                placeholder="Select a date"
                required
              />
            </div>
          </div>

          <span class="form__error">
                <span v-if="invalidDate">Please select a valid date range.</span>
            </span>
        </label>

        <label class="form__label label-switch switch-success" v-if="!isDefaultPriceList">

          <input
            v-model="isForAllCustomers"
            class="switch switch-bootstrap status"
            hidden
            name="allCustomers"
            type="checkbox"
            @change.prevent="handleIsForAllCustomers"
          />
          <span class="lable">All customers</span>
        </label>

        <!-- <label  v-if="!isDefaultPriceList"
                :class="{'tw-opacity-50 tw-cursor-not-allowed': isForAllCustomers}"
                class="form__label label-switch switch-success">
          <input
            v-model="availableForNewCustomers"
            :disabled="isForAllCustomers"
            class="switch switch-bootstrap status"
            hidden
            name="addNewCustomers"
            type="checkbox"
          />
          <span class="lable">Add new customers to this pricelist</span>
        </label> -->
        <!-- </div> -->

      </div>


      <div class="">
        <ul v-cloak class="tabs tw-justify-start tw-mb-1 tw-gap-1 tw-items-start">
          <li class="tabs__item tw-max-w-xs tw-m-0">
            <button
              :class="{'tabs__link_active': tabName == 'price'}"
              class="tabs__link tw-uppercase"
              @click.prevent="tabName = 'price'"
            >
              Product Margins
            </button>
          </li>
          <li v-if="!isForAllCustomers && !isDefaultPriceList" class="tabs__item  tw-max-w-xs tw-m-0">
            <button
              :class="{tabs__link_active: tabName == 'customer'}"
              class="tabs__link tw-uppercase"
              @click.prevent="tabName = 'customer'"
            >
              Customers
            </button>
          </li>
        </ul>
        <InlinePreloader v-if="isLoadingPricelist" message="Loading margins"></InlinePreloader>

        <div v-else>
          <keep-alive>
            <CustomerComponent v-if="tabName === 'customer' && !isForAllCustomers"
                               :pricelistCustomers="isEdit? customers: []" :pricelistId="pricelistId"
                               @pricelistCustomersUpdated="handleCustomerUpdate"></CustomerComponent>
            <ProductPricesComponent v-else :isDefaultPriceList="isDefaultPriceList"
                                    :priceListType="type"
                                    :defaultMargin="defaultMargin?.replaceAll(',','.')"
                                    :productsMargin="isEdit? currentData.latest_values.product_margins: []"
                                    @product-margin-updated="handleProductUpdate"
            ></ProductPricesComponent>
          </keep-alive>
        </div>
      </div>
    </Form>
  </div>

  <Modal v-if="showSuccessModal" :handleClose="() => gotoListView()">
    <template #title-text>
      Success
    </template>

    <template #description>
      <div>
        <div>Price list {{ isEdit ? 'updated' : 'created' }}</div>

        <div class="tw-flex tw-gap-4 tw-mt-4">
          <button class="tw-btn-secondary-success" type="button" @click="gotoListView">
            OK
          </button>
        </div>
      </div>
    </template>

    <template #footer>
      {{ null }}
    </template>
  </Modal>

  <Modal v-if="showAllCustomerConfirmation"
         :handleClose="() => {isForAllCustomers = !isForAllCustomers; showAllCustomerConfirmation = false}">
    <template #title-text>
      Confirm
    </template>

    <template #description>
      <div>
        <div>Do you want to continue</div>

        <div class="tw-flex tw-gap-4 tw-mt-4">
          <button class="tw-btn-primary-success" type="button"
                  @click="showAllCustomerConfirmation = false">
            Yes
          </button>
          <button class="tw-btn-secondary-success" type="button"
                  @click="() => {isForAllCustomers = !isForAllCustomers; showAllCustomerConfirmation = false}">
            No
          </button>
        </div>
      </div>
    </template>

    <template #footer>
      {{ null }}
    </template>
  </Modal>
</template>

<script setup>
import {computed, ref} from '@vue/reactivity'
import {watch} from '@vue/runtime-core'
import {defineAsyncComponent, onMounted} from "vue";
// import Datepicker from "vue3-datepicker";
import Datepicker from "vue3-date-time-picker";
//import "vue3-date-time-picker/dist/main.css";
import moment from 'moment'
import _ from 'lodash'
import {useRouter} from 'vue-router';

import useCreatePriceList from '../../../../hooks/price-lists/useCreatePriceList';
import useUpdatePriceList from '../../../../hooks/price-lists/useUpdatePriceList';
import useGetPriceListProductMargins
  from '../../../../hooks/price-lists/useGetPriceListProductMargins';
import useGetCustomersByPricelist from '../../../../hooks/price-lists/useGetCustomersByPricelist';
import useAttachCustomers from '../../../../hooks/price-lists/useAttachCustomers';
import InlinePreloader from '../../../../components/parts/inlinePreloader.vue';
import Modal from '../../../../components/modals/Modal.vue';
import useGetInstallationFee from "@/hooks/price-lists/useGetInstallationFee";
import SvgIcon from "@/components/SvgIconNew.vue";
import useSetInstallationFee from "@/hooks/price-lists/useSetInstallationFee";

const {createPriceList, createPriceListStatus} = useCreatePriceList()
const {updatePriceList, updatePriceListStatus} = useUpdatePriceList()
const ProductPricesComponent = defineAsyncComponent(() =>
  import("./ProductPrices.vue")
);
const CustomerComponent = defineAsyncComponent(() =>
  import("./Customers.vue")
);
const tabName = ref('price')
const router = useRouter()
const props = defineProps({
  pricelistId: {
    type: String,
    default: undefined
  }
})

const type = ref('')
const markThisPriceListAsTheDefaultOne = ref(false)

const isDefaultPriceList = computed(() => router.currentRoute.value.name === 'business-details.default-price-list') // currentData && currentData.value && currentData.value.is_default)

const isEdit = computed(() => !!props.pricelistId || isDefaultPriceList.value)

const {
  data: currentData,
  isLoading: isLoadingPricelist
} = useGetPriceListProductMargins(isDefaultPriceList.value ? 'default' : props.pricelistId)
const {
  data: customers,
  isLoading: isCustomersLoading
} = useGetCustomersByPricelist(props.pricelistId)
const {attachCustomers, attachCustomersStatus} = useAttachCustomers()

const { data:installationFee } = useGetInstallationFee()
const isEditInstallationFee = ref(false)
const { setInstallationFee, setInstallationFeeStatus } = useSetInstallationFee()
const installationFeeValue = ref(installationFee.value)
watch(installationFee, (val) => {installationFeeValue.value = installationFee.value})
const defaultMargin = ref(0)

let formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  // if (date[1]) {
  //   const toDay = date[1].getDate();
  //   const toMonth = date[1].getMonth() + 1;
  //   const toYear = date[1].getFullYear();
  //   return `${day}/${month}/${year} - ${toDay}/${toMonth}/${toYear} `;
  // }
  return `${day}/${month}/${year} `;
};

const startDate = ref(null)
const endDate = ref(null)
const invalidDate = ref(false)
const availableForNewCustomers = ref(false)
const isForAllCustomers = ref(true)
const showAllCustomerConfirmation = ref(false)

const handleIsForAllCustomers = (e) => {
  showAllCustomerConfirmation.value = true
  // console.log('Handle...', e.target.checked)
  // isForAllCustomers.value = e.target.checked//!isForAllCustomers.value
}

// Set initial values if it is on edit mode
watch(isLoadingPricelist, (val, oldVal) => {
  const start = _.get(currentData.value?.latest_values, 'start_date', null)
  const end = _.get(currentData.value?.latest_values, 'end_date', null)
  startDate.value = start ? moment(start) : null
  endDate.value = end? moment(end): null
  defaultMargin.value = currentData.value?.latest_values.margin
  type.value = currentData.value?.type ?? 'variable'

  isForAllCustomers.value = _.get(currentData.value, 'is_for_all_customers', false)
  availableForNewCustomers.value = isForAllCustomers.value?.latest_values ? false : _.get(currentData.value, 'available_for_new_customers', false)

}, {deep: true, immediate: true})

watch(isForAllCustomers, (val, oldVal) => {
  if (val) {
    availableForNewCustomers.value = false
  }
}, {immediate: true})

watch(type,(value) => {
  if(value === 'fixed')  markThisPriceListAsTheDefaultOne.value = false
})

const showSuccessModal = ref(false)
const newPricelistId = ref(null)

const onSubmit = async (values) => {

  if (!startDate.value) return invalidDate.value = true
  invalidDate.value = false
  let start = moment(startDate.value).format('YYYY-MM-DD')
  let end = moment(endDate.value).format('YYYY-MM-DD')

  if (!(moment(end).isValid())) {
    end = null
  }

  let payload = {
    name: values.name,
    type: values.priceListType,
    margin: values.margin.replaceAll(',','.'),
    startDate: start,
    isForAllCustomers: isForAllCustomers.value,
    availableForNewCustomers: availableForNewCustomers.value,
    isDefault: isDefaultPriceList.value, //!isEdit.value ?  markThisPriceListAsTheDefaultOne.value : isDefaultPriceList.value,
    productMarginList: updatedProducts.value.map(i => ({
      product_type_id: i.product_type_id,
      product_style_id: i.product_style_id,
      product_grade_id: i.product_grade_id,
      margin: i.margin || 0,
      price: i.price || 0
    }))

  }

   payload.endDate = end

  let response = null
  if (isEdit.value) {
    response = await updatePriceList({...payload, priceListId: isDefaultPriceList.value ? currentData.value.id : props.pricelistId})
  } else {
    await createPriceList(payload)
    //TODO: Attach customers if any; Get the price list id from the response and add it.
  }

  const attachedCustomerIds = updatedCustomers.value.map(x => x.id)
  if (attachedCustomerIds.length > 0 && !isEdit.value) {
    await attachCustomers({
      // pricelistId: isEdit.value && response ? response.new_price_list_id : createPriceListStatus.data.id,
      pricelistId: isEdit.value && response ? currentData.value.id : createPriceListStatus.data.id,
      customerIds: attachedCustomerIds
    })
  }

  showSuccessModal.value = true
};


const updatedProducts = ref([])

const updateInstallationFee = async () => {
    if (setInstallationFeeStatus.value === 'Loading') return
    await setInstallationFee({ payload: installationFeeValue.value})
    isEditInstallationFee.value = false
}

const handleProductUpdate = (val) => {
  updatedProducts.value = val
}
const updatedCustomers = ref([])

const handleCustomerUpdate = (val) => {
  updatedCustomers.value = val
}

const goToEditView = () => {
  router.replace(
    {
      name: 'price-lists.edit', params: {pricelistId: updatePriceListStatus.data.new_price_list_id}
    })
  showSuccessModal.value = false
}

const gotoListView = () => {
  showSuccessModal.value = false
  if (!isDefaultPriceList.value) {
    router.push({name: 'price-lists.index'})
  }
}


onMounted(async () => {
  if(!isEdit.value) {
    startDate.value = new Date();
  }
  type.value = 'variable'
})

</script>

<style lang="scss">

.dp__input {
  @apply tw-border-secondary tw-border-2 hover:tw-border-primary;
}

.installation_fee{
  width: 60px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom-width: 1px;
  text-align: right;
  padding: 0;
}

</style>

