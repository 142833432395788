import { useMutation, useQueryClient } from "vue-query";
// import httpClient from "@/http/httpClient";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
// import { useToast } from "vue-toastification";
// const toast = useToast();
import { ref, toRefs, computed, reactive } from "@vue/reactivity";
import _ from 'lodash'

const action = async (payload) => {
  const { pricelistId } = payload;
  const { customerIds } = payload;
  await httpReq.post(`ordering-portal/account-information-maintenance/price-lists/${pricelistId}/customers`, {
    customer_ids: customerIds
  });
};

export default function useAttachCustomers() {
  const cache = useQueryClient();
  const mutation = useMutation((payload) => action(payload), {
    onError: (error) => {
      console.error('Error on useAttachCustomers: ', error)
      store.commit("errorsModal/openModal", _.get(error, 'response.data.errors', [{0:'Something went wrong'}]));
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["price-lists-customers"]);
      cache.invalidateQueries(["price-lists"]);
      cache.invalidateQueries(["price-list-customers-for",  { pricelistId: variables.pricelistId }]);
      // toast.success("Account update.");
    },
    onSettled: (data, error, variables, context) => {},    
  });

  return reactive({
    attachCustomers: mutation.mutateAsync,
    attachCustomersStatus: mutation,
  });
}
