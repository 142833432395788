import { useQuery } from "vue-query";
import { httpReq } from "@/htttpReq";


const fetchData = async () => {

  const response = await httpReq.get(`ordering-portal/account-information-maintenance/price-lists/installation-fee`);
  return response.data.data;
};

export default function useGetInstallationFee() {

  const queryKey = ["installation-fee"];

  return useQuery(
    queryKey,
    () => fetchData(),
    {
      refetchOnWindowFocus: false,
      //   placeholderData: [],
        staleTime: Infinity,
      onError: () => {
        // store.commit("errorsModal/openModal", error.response.data.errors);
      },
    },

  );
}
