import { useMutation, useQueryClient } from "vue-query";
import { httpReq } from "@/htttpReq";
import store from '@/store/store'
import { ref, toRefs, computed, reactive } from "@vue/reactivity";

const action = async (payload) => {
  await httpReq.put(`ordering-portal/account-information-maintenance/price-lists/installation-fee`, {installation_fee : payload});
};

export default function useSetInstallationFee() {
  const cache = useQueryClient();
  const mutation = useMutation(({ payload }) => action(payload), {
    onError: (error) => {
      store.commit("errorsModal/openModal", error.response.data.errors);
    },
    onSuccess: (data, variables, context) => {
      cache.invalidateQueries(["installation-fee"]);
    },
    onSettled: (data, error, variables, context) => {},
  });

  return reactive({
    setInstallationFee: mutation.mutateAsync,
    setInstallationFeeStatus: mutation,
  });
}
